$FontPath: "./assets/fonts" !default;
$RubikVersion: "1.0" !default;
$knockoutVersion: "1.0" !default;

@mixin fontRubik($FontPath, $RubikVersion, $FontType: "400normal_l") {
    src: url("#{$FontPath}/rubik/rubik_#{$FontType}.woff2?v=#{$RubikVersion}") format("woff2");
}

@mixin fontKnockout($FontPath, $knockoutVersion, $FontType: "73FullHeviweight") {
    src: url("#{$FontPath}/knockout/Knockout-#{$FontType}.woff2?v=#{$knockoutVersion}") format("woff2");
}

/* #### FONT KNOCKOUT #### */
@font-face {
    font-family: "Knockout-73FullHeviweight";
    font-weight: normal;
    font-style: normal;
    font-display: auto;
    @include fontKnockout($FontPath, $knockoutVersion, "73FullHeviweight");
}
/* #### END #### */

/* #### FONT RUBIK #### */
/* cyrillic-ext */
@font-face {
    font-family: "Rubik";
    font-style: italic;
    font-weight: 300;
    font-display: auto;
    @include fontRubik($FontPath, $RubikVersion, "300italic_c_ext");
    unicode-range: U+0460-052F, U+1C80-1C88, U+20B4, U+2DE0-2DFF, U+A640-A69F, U+FE2E-FE2F;
}
/* cyrillic */
@font-face {
    font-family: "Rubik";
    font-style: italic;
    font-weight: 300;
    font-display: auto;
    @include fontRubik($FontPath, $RubikVersion, "300italic_c");
    unicode-range: U+0400-045F, U+0490-0491, U+04B0-04B1, U+2116;
}
/* hbrew */
@font-face {
    font-family: "Rubik";
    font-style: italic;
    font-weight: 300;
    font-display: auto;
    @include fontRubik($FontPath, $RubikVersion, "300italic_hbrew");
    unicode-range: U+0590-05FF, U+20AA, U+25CC, U+FB1D-FB4F;
}
/* latin-ext */
@font-face {
    font-family: "Rubik";
    font-style: italic;
    font-weight: 300;
    font-display: auto;
    @include fontRubik($FontPath, $RubikVersion, "300italic_l_ext");
    unicode-range: U+0100-024F, U+0259, U+1E00-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
}
/* latin */
@font-face {
    font-family: "Rubik";
    font-style: italic;
    font-weight: 300;
    font-display: auto;
    @include fontRubik($FontPath, $RubikVersion, "300italic_l");
    unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC,
        U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}
/* cyrillic-ext */
@font-face {
    font-family: "Rubik";
    font-style: italic;
    font-weight: 400;
    font-display: auto;
    @include fontRubik($FontPath, $RubikVersion, "400italic_c_ext");
    unicode-range: U+0460-052F, U+1C80-1C88, U+20B4, U+2DE0-2DFF, U+A640-A69F, U+FE2E-FE2F;
}
/* cyrillic */
@font-face {
    font-family: "Rubik";
    font-style: italic;
    font-weight: 400;
    font-display: auto;
    @include fontRubik($FontPath, $RubikVersion, "400italic_c");
    unicode-range: U+0400-045F, U+0490-0491, U+04B0-04B1, U+2116;
}
/* hbrew */
@font-face {
    font-family: "Rubik";
    font-style: italic;
    font-weight: 400;
    font-display: auto;
    @include fontRubik($FontPath, $RubikVersion, "400italic_hbrew");
    unicode-range: U+0590-05FF, U+20AA, U+25CC, U+FB1D-FB4F;
}
/* latin-ext */
@font-face {
    font-family: "Rubik";
    font-style: italic;
    font-weight: 400;
    font-display: auto;
    @include fontRubik($FontPath, $RubikVersion, "400italic_l_ext");
    unicode-range: U+0100-024F, U+0259, U+1E00-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
}
/* latin */
@font-face {
    font-family: "Rubik";
    font-style: italic;
    font-weight: 400;
    font-display: auto;
    @include fontRubik($FontPath, $RubikVersion, "400italic_l");
    unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC,
        U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}
/* cyrillic-ext */
@font-face {
    font-family: "Rubik";
    font-style: italic;
    font-weight: 700;
    font-display: auto;
    @include fontRubik($FontPath, $RubikVersion, "700italic_c_ext");
    unicode-range: U+0460-052F, U+1C80-1C88, U+20B4, U+2DE0-2DFF, U+A640-A69F, U+FE2E-FE2F;
}
/* cyrillic */
@font-face {
    font-family: "Rubik";
    font-style: italic;
    font-weight: 700;
    font-display: auto;
    @include fontRubik($FontPath, $RubikVersion, "700italic_c");
    unicode-range: U+0400-045F, U+0490-0491, U+04B0-04B1, U+2116;
}
/* hbrew */
@font-face {
    font-family: "Rubik";
    font-style: italic;
    font-weight: 700;
    font-display: auto;
    @include fontRubik($FontPath, $RubikVersion, "700italic_hbrew");
    unicode-range: U+0590-05FF, U+20AA, U+25CC, U+FB1D-FB4F;
}
/* latin-ext */
@font-face {
    font-family: "Rubik";
    font-style: italic;
    font-weight: 700;
    font-display: auto;
    @include fontRubik($FontPath, $RubikVersion, "700italic_l_ext");
    unicode-range: U+0100-024F, U+0259, U+1E00-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
}
/* latin */
@font-face {
    font-family: "Rubik";
    font-style: italic;
    font-weight: 700;
    font-display: auto;
    @include fontRubik($FontPath, $RubikVersion, "700italic_l");
    unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC,
        U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}
/* cyrillic-ext */
@font-face {
    font-family: "Rubik";
    font-style: normal;
    font-weight: 300;
    font-display: auto;
    @include fontRubik($FontPath, $RubikVersion, "300normal_c_ext");
    unicode-range: U+0460-052F, U+1C80-1C88, U+20B4, U+2DE0-2DFF, U+A640-A69F, U+FE2E-FE2F;
}
/* cyrillic */
@font-face {
    font-family: "Rubik";
    font-style: normal;
    font-weight: 300;
    font-display: auto;
    @include fontRubik($FontPath, $RubikVersion, "300normal_c");
    unicode-range: U+0400-045F, U+0490-0491, U+04B0-04B1, U+2116;
}
/* hbrew */
@font-face {
    font-family: "Rubik";
    font-style: normal;
    font-weight: 300;
    font-display: auto;
    @include fontRubik($FontPath, $RubikVersion, "300normal_hbrew");
    unicode-range: U+0590-05FF, U+20AA, U+25CC, U+FB1D-FB4F;
}
/* latin-ext */
@font-face {
    font-family: "Rubik";
    font-style: normal;
    font-weight: 300;
    font-display: auto;
    @include fontRubik($FontPath, $RubikVersion, "300normal_l_ext");
    unicode-range: U+0100-024F, U+0259, U+1E00-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
}
/* latin */
@font-face {
    font-family: "Rubik";
    font-style: normal;
    font-weight: 300;
    font-display: auto;
    @include fontRubik($FontPath, $RubikVersion, "300normal_l");
    unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC,
        U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}
/* cyrillic-ext */
@font-face {
    font-family: "Rubik";
    font-style: normal;
    font-weight: 400;
    font-display: auto;
    @include fontRubik($FontPath, $RubikVersion, "400normal_c_ext");
    unicode-range: U+0460-052F, U+1C80-1C88, U+20B4, U+2DE0-2DFF, U+A640-A69F, U+FE2E-FE2F;
}
/* cyrillic */
@font-face {
    font-family: "Rubik";
    font-style: normal;
    font-weight: 400;
    font-display: auto;
    @include fontRubik($FontPath, $RubikVersion, "400normal_c");
    unicode-range: U+0400-045F, U+0490-0491, U+04B0-04B1, U+2116;
}
/* hbrew */
@font-face {
    font-family: "Rubik";
    font-style: normal;
    font-weight: 400;
    font-display: auto;
    @include fontRubik($FontPath, $RubikVersion, "400normal_hbrew");
    unicode-range: U+0590-05FF, U+20AA, U+25CC, U+FB1D-FB4F;
}
/* latin-ext */
@font-face {
    font-family: "Rubik";
    font-style: normal;
    font-weight: 400;
    font-display: auto;
    @include fontRubik($FontPath, $RubikVersion, "400normal_l_ext");
    unicode-range: U+0100-024F, U+0259, U+1E00-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
}
/* latin */
@font-face {
    font-family: "Rubik";
    font-style: normal;
    font-weight: 400;
    font-display: auto;
    @include fontRubik($FontPath, $RubikVersion, "400normal_l");
    unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC,
        U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}
/* cyrillic-ext */
@font-face {
    font-family: "Rubik";
    font-style: normal;
    font-weight: 700;
    font-display: auto;
    @include fontRubik($FontPath, $RubikVersion, "700normal_c_ext");
    unicode-range: U+0460-052F, U+1C80-1C88, U+20B4, U+2DE0-2DFF, U+A640-A69F, U+FE2E-FE2F;
}
/* cyrillic */
@font-face {
    font-family: "Rubik";
    font-style: normal;
    font-weight: 700;
    font-display: auto;
    @include fontRubik($FontPath, $RubikVersion, "700normal_c");
    unicode-range: U+0400-045F, U+0490-0491, U+04B0-04B1, U+2116;
}
/* hbrew */
@font-face {
    font-family: "Rubik";
    font-style: normal;
    font-weight: 700;
    font-display: auto;
    @include fontRubik($FontPath, $RubikVersion, "700normal_hbrew");
    unicode-range: U+0590-05FF, U+20AA, U+25CC, U+FB1D-FB4F;
}
/* latin-ext */
@font-face {
    font-family: "Rubik";
    font-style: normal;
    font-weight: 700;
    font-display: auto;
    @include fontRubik($FontPath, $RubikVersion, "700normal_l_ext");
    unicode-range: U+0100-024F, U+0259, U+1E00-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
}
/* latin */
@font-face {
    font-family: "Rubik";
    font-style: normal;
    font-weight: 700;
    font-display: auto;
    @include fontRubik($FontPath, $RubikVersion, "700normal_l");
    unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC,
        U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}
/* #### END #### */
