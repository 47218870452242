@import "assets/scss/global/variables-custom";

@import "./assets/scss/global/fonts";

@import "~slick-carousel/slick/slick";

@import "./assets/scss/modules";

@import "./assets/scss/global/utils";

input,
textarea,
button,
select,
a {
    -webkit-tap-highlight-color: transparent;
}
