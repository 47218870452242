$slick-loader-path: "~slick-carousel/slick/";
$slick-font-path: "~slick-carousel/slick/fonts/";

// COLORS
$white: #ffffff;
$black: #000000;
$miscusi_red: #992631;
$cream: #ede5d5;
$creamer: #fff9ec;
$yellow: #ebc474;
$dark_green: #546d67;
$light_green: #85bca7;
$darker_green: #85bca7;
$beige: #d6c2b0;
$brown: #9e7855;
$cyan: #9ed1d7;
$cyan-2: #4c9aa4;
$orange: #d78239;
$dimgray: #514d45;
$satispay_red: #ff1f33;

$Light_Beige: #e1d0b5;
$Primary-White: #fffefe;
$Primary-Black: #1a1919;
$Primary-LightRed: #c15a4c;
$nepal: #91baa8;
$Second-LightGrey: #f1f1f1;
$Secondary-Solid-Bg-LightGreen: #86bca7;
$Miscusi-Red: #992631;
$Miscusi-Blue: #3d739f;
$Solid-Cream: #f0ebe1;
$Light-Cream: #f9f7f6;
$Miscusi-Grey: #9c9c9c;

//#### UIKIT
//BREAKPOINT
$breakpoint-small: 640px; // Phone landscape
$breakpoint-medium: 960px; // Tablet Landscape
$breakpoint-large: 1120px; // Desktop
$breakpoint-large2: 1200px; // Desktop v2
$breakpoint-large2-max: ($breakpoint-large2 - 1); // max-width Desktop v2
$breakpoint-xlarge: 1600px; // Large Screens
$breakpoint-xxlarge: 2040px; // Extra Large Screens
$breakpoint-xlarge-max: ($breakpoint-xxlarge - 1); // max-width Extra Large Screens
$breakpoint-small-max: ($breakpoint-medium - 1);
$breakpoint-medium-max: ($breakpoint-large - 1);
$breakpoint-xsmall-max: ($breakpoint-small - 1);

//GLOBAL
$global-color: $Primary-Black;
$global-inverse-color: $cream;
$inverse-global-color: $cream;
$global-link-color: $Primary-Black;
$global-link-hover-color: $Primary-Black;
$global-primary-background: $Primary-LightRed;
$global-emphasis-background: $Miscusi-Red;
$base-body-font-family: "Rubik", sans-serif;
$base-heading-font-family: "Knockout-73FullHeviweight", sans-serif;
$base-link-hover-text-decoration: none;
$base-heading-color: $global-color;
$base-h1-font-size-m: 32px;
$base-h1-font-size: 24px;
$container-padding-horizontal: 16px;
$container-max-width: 1200px; //> wide:1920px = 1400
$container-small-max-width: 968px;
$global-background: $cream !default;
$global-muted-background: $cream !default;
$global-small-gutter: 15px !default;
$global-xlarge-margin: 80px !default;
$global-xlarge-margin-l: 120px !default;
$global-xxlarge-margin: 160px !default;
$global-xxxlarge-margin: 320px !default;
//BUTTON
$button-small-line-height: 48px;
$button-line-height: 50px;
$button-large-line-height: 64px;
$button-small-font-size: 13px;
$button-font-size: 13px;
$button-large-font-size: 13px;
$button-primary-background: $Miscusi-Red;
$button-small-padding-horizontal: 24px;
$button-padding-horizontal: 24px;

//FORM
$form-padding-horizontal: 16px;
$form-large-height: 50px;
$form-large-font-size: 16px;
$form-large-padding-horizontal: 16px;
$form-background: transparent;
$form-focus-background: transparent;
$form-placeholder-color: $Primary-Black;

//BORDER
$border-rounded-border-radius: 10px !default;

//ACCORDION
$accordion-item-margin-top: 16px;
$accordion-title-font-size: 16px;
$accordion-title-color: $Primary-Black;
$accordion-title-line-height: inherit;

//HEADING
$base-h2-font-size: 18px;
$base-h3-font-size: 13px;

//OFFCANVAS
$offcanvas-z-index: 99999;
$offcanvas-bar-width: calc(100%);
$offcanvas-bar-padding-vertical: 0px;
$offcanvas-bar-padding-horizontal: 0px;
$offcanvas-bar-background: $Primary-White;
$offcanvas-bar-color-mode: dark;
$offcanvas-bar-width-m: 500px;
$offcanvas-bar-padding-vertical-m: $offcanvas-bar-padding-vertical;
$offcanvas-bar-padding-horizontal-m: $offcanvas-bar-padding-horizontal;
$offcanvas-close-position: 16px;

//NAVBAR
$navbar-nav-item-font-size: 13px;
$navbar-nav-item-font-family: "Rubik", sans-serif;
$navbar-dropdown-padding: 0;
$navbar-dropdown-z-index: 979;

//MODAL
$modal-background: rgba($Primary-Black, 0.9);
$modal-dialog-width: 640px;
$modal-dialog-background: $cream;
$modal-close-outside-position: -22px;

//ANIMATION
$animation-fade-duration: 0.3s !default;
