// Name:            Container
// Description:     Component to align and center your site and grid content
//
// Component:       `uk-container`
//
// Modifier:        `uk-container-small`
//                  `uk-container-large`
//                  `uk-container-expand`
//                  `uk-container-expand-left`
//                  `uk-container-expand-right`
//                  `uk-container-item-padding-remove-left`
//                  `uk-container-item-padding-remove-right`
//
// ========================================================================

// Variables
// ========================================================================

$container-max-width: 1200px !default;
$container-xsmall-max-width: 750px !default;
$container-small-max-width: 900px !default;
$container-large-max-width: 1400px !default;
$container-xlarge-max-width: 1600px !default;

$container-padding-horizontal: 15px !default;
$container-padding-horizontal-s: $global-gutter !default;
$container-padding-horizontal-m: $global-medium-gutter !default;

/* ========================================================================
   Component: Container
 ========================================================================== */

/*
 * 1. Box sizing has to be `content-box` so the max-width is always the same and
 *    unaffected by the padding on different breakpoints. It's important for the size modifiers.
 */

.uk-container {
    display: flow-root;
    /* 1 */
    box-sizing: content-box;
    max-width: $container-max-width;
    margin-left: auto;
    margin-right: auto;
    padding-left: $container-padding-horizontal;
    padding-right: $container-padding-horizontal;
}

/* Phone landscape and bigger */
@media (min-width: $breakpoint-small) {
    .uk-container {
        padding-left: $container-padding-horizontal-s;
        padding-right: $container-padding-horizontal-s;
    }
}

/* Tablet landscape and bigger */
@media (min-width: $breakpoint-medium) {
    .uk-container {
        padding-left: $container-padding-horizontal-m;
        padding-right: $container-padding-horizontal-m;
    }
}

/*
 * Remove margin from the last-child
 */

.uk-container > :last-child {
    margin-bottom: 0;
}

/*
 * Remove padding from nested containers
 */

.uk-container .uk-container {
    padding-left: 0;
    padding-right: 0;
}

/* Size modifier
 ========================================================================== */

.uk-container-xsmall {
    max-width: $container-xsmall-max-width;
}

.uk-container-small {
    max-width: $container-small-max-width;
}

.uk-container-large {
    max-width: $container-large-max-width;
}

.uk-container-xlarge {
    max-width: $container-xlarge-max-width;
}

.uk-container-expand {
    max-width: none;
}

/* Expand modifier
 ========================================================================== */

/*
 * Expand one side only
 */

.uk-container-expand-left {
    margin-left: 0;
}
.uk-container-expand-right {
    margin-right: 0;
}

/* Phone landscape and bigger */
@media (min-width: $breakpoint-small) {
    .uk-container-expand-left.uk-container-xsmall,
    .uk-container-expand-right.uk-container-xsmall {
        max-width: unquote("calc(50% + (#{$container-xsmall-max-width} * 0.5) - #{$container-padding-horizontal-s})");
    }

    .uk-container-expand-left.uk-container-small,
    .uk-container-expand-right.uk-container-small {
        max-width: unquote("calc(50% + (#{$container-small-max-width} * 0.5) - #{$container-padding-horizontal-s})");
    }
}

/* Tablet landscape and bigger */
@media (min-width: $breakpoint-medium) {
    .uk-container-expand-left,
    .uk-container-expand-right {
        max-width: unquote("calc(50% + (#{$container-max-width} * 0.5) - #{$container-padding-horizontal-m})");
    }

    .uk-container-expand-left.uk-container-xsmall,
    .uk-container-expand-right.uk-container-xsmall {
        max-width: unquote("calc(50% + (#{$container-xsmall-max-width} * 0.5) - #{$container-padding-horizontal-m})");
    }

    .uk-container-expand-left.uk-container-small,
    .uk-container-expand-right.uk-container-small {
        max-width: unquote("calc(50% + (#{$container-small-max-width} * 0.5) - #{$container-padding-horizontal-m})");
    }

    .uk-container-expand-left.uk-container-large,
    .uk-container-expand-right.uk-container-large {
        max-width: unquote("calc(50% + (#{$container-large-max-width} * 0.5) - #{$container-padding-horizontal-m})");
    }

    .uk-container-expand-left.uk-container-xlarge,
    .uk-container-expand-right.uk-container-xlarge {
        max-width: unquote("calc(50% + (#{$container-xlarge-max-width} * 0.5) - #{$container-padding-horizontal-m})");
    }
}

/* ItemGift
 ========================================================================== */

/*
 * Utility classes to reset container padding on the left or right side
 * Note: It has to be negative margin on the item, because it's specific to the item.
 */

.uk-container-item-padding-remove-left,
.uk-container-item-padding-remove-right {
    width: unquote("calc(100% + #{$container-padding-horizontal})");
}

.uk-container-item-padding-remove-left {
    margin-left: (-$container-padding-horizontal);
}
.uk-container-item-padding-remove-right {
    margin-right: (-$container-padding-horizontal);
}

/* Phone landscape and bigger */
@media (min-width: $breakpoint-small) {
    .uk-container-item-padding-remove-left,
    .uk-container-item-padding-remove-right {
        width: unquote("calc(100% + #{$container-padding-horizontal-s})");
    }

    .uk-container-item-padding-remove-left {
        margin-left: (-$container-padding-horizontal-s);
    }
    .uk-container-item-padding-remove-right {
        margin-right: (-$container-padding-horizontal-s);
    }
}

/* Tablet landscape and bigger */
@media (min-width: $breakpoint-medium) {
    .uk-container-item-padding-remove-left,
    .uk-container-item-padding-remove-right {
        width: unquote("calc(100% + #{$container-padding-horizontal-m})");
    }

    .uk-container-item-padding-remove-left {
        margin-left: (-$container-padding-horizontal-m);
    }
    .uk-container-item-padding-remove-right {
        margin-right: (-$container-padding-horizontal-m);
    }
}

// Hooks
// ========================================================================

@if (mixin-exists(hook-container-misc)) {
    @include hook-container-misc();
}

// @mixin hook-container-misc(){}
