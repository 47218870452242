@import "./variables-custom";

//RESET
button {
    font-family: "Rubik", sans-serif;
    background: none;
    border: 0;
    cursor: pointer;
    padding: 0;
    -webkit-appearance: none;
    transition: all 0.3s ease-in-out;
}

* {
    box-sizing: border-box;
    outline: none;
}

:root {
    --venia-anim-bounce: cubic-bezier(0.5, 1.8, 0.9, 0.8);
    --venia-anim-in: cubic-bezier(0, 0, 0.2, 1);
    --venia-anim-out: cubic-bezier(0.4, 0, 1, 1);
    --venia-anim-standard: cubic-bezier(0.4, 0, 0.2, 1);
    --venia-background-color: 255, 255, 255;
    --venia-border: 224, 224, 224;
    --venia-error: 192, 18, 63;
    --venia-error-alt: 255, 226, 234;
    --venia-font: sans-serif;
    --venia-grey: 246, 246, 246;
    --venia-grey-dark: 209, 209, 209;
    --venia-grey-darker: 112, 112, 112;
    --venia-orange: 241, 99, 33;
    --venia-teal: 0, 115, 120;
    --venia-teal-alt: 224, 240, 241;
    --venia-teal-dark: 0, 104, 108;
    --venia-teal-light: 212, 243, 238;
    --venia-text: 33, 33, 33;
    --venia-text-alt: var(--venia-grey-darker);
    --venia-text-hint: 158, 158, 158;
    --venia-text-spot: 255, 99, 51;
    --venia-warning-dark: 249, 93, 94;
    --venia-warning-light: 254, 229, 232;
    --venia-cta-red: 236, 29, 35;
    --venia-cta-black: 0, 0, 0;
    --venia-cta-white: 255, 255, 255;
    --venia-cta-fume-black: 12, 13, 13;
    --venia-cta-medium-grey: 158, 158, 158;
    --venia-cta-dark-grey: 86, 86, 86;
    --venia-cta-ultralight-grey: 231, 231, 231;
    --venia-cta-brown: 183, 98, 50;
    --venia-cta-orange: 255, 115, 0;
    --venia-cta-vibrant-violet: 83, 80, 255;
    --venia-cta-green: 33, 202, 92;
    --venia-cta-imperceptible: 245, 245, 245;
    --venia-cta-graphite: 131, 131, 138;
    --venia-cta-carbon: 18, 19, 21;
    --venia-cta-petrol: 27, 29, 31;
    --venia-cta-light-blue: 57, 240, 231;
    --venia-cta-gold: 251, 197, 57;
    --venia-cta-facebook-blue: 62, 113, 249;
    --safe-area-inset-top: env(safe-area-inset-top);
    --safe-area-inset-bottom: env(safe-area-inset-bottom);
    --safe-area-inset-left: env(safe-area-inset-left);
    --safe-area-inset-right: env(safe-area-inset-right);

    //custom variables
    --rubik-font: "Rubik", sans-serif;
}

html,
body {
    text-rendering: geometricprecision;
    overscroll-behavior: none;
}

//BG COLOR
.uk-background-white {
    background-color: $Primary-White;
}
.uk-background-dark {
    background-color: $black;
}
.uk-background-nepal {
    background-color: $nepal;
}
.uk-background-green {
    background-color: $dark_green;
}
.uk-background-light-green {
    background-color: $Secondary-Solid-Bg-LightGreen;
}
.uk-background-cream {
    background-color: $cream !important;
}
.uk-background-creamer {
    background-color: $creamer !important;
}
.uk-background-light-beige {
    background-color: $Light_Beige !important;
}
.uk-background-miscusi {
    background-color: $Miscusi-Red;
}
.uk-blue-miscusi {
    color: $Miscusi-Blue;
}
.uk-background-transparent {
    background-color: transparent;
}

//TEXT COLOR
.uk-text-white {
    color: $Primary-White !important;
}
.uk-text-green {
    color: $dark_green !important;
}
.uk-text-lightgreen {
    color: $light_green !important;
}
.uk-text-nepal {
    color: $nepal !important;
}
.uk-text-dimgray {
    color: $dimgray !important;
}
.uk-text-light-green {
    color: $Secondary-Solid-Bg-LightGreen;
}
.uk-text-black {
    color: black !important;
}

.page-standard {
    padding-top: 140px;
    @media (min-width: $breakpoint-medium) {
        padding-top: 172px;
    }
}

//RESET CLASS
.link {
    display: inherit !important;
    height: auto !important;
    &:hover {
        background-color: inherit !important;
    }
}

@media (min-width: $breakpoint-large2) {
    .uk-hidden\@l-2 {
        display: none !important;
    }
}
@media (max-width: $breakpoint-large2-max) {
    .uk-visible\@l-2 {
        display: none !important;
    }
}

//BUTTON
.uk-button {
    border-radius: 5px;
    text-transform: uppercase;

    font-weight: bold;
    letter-spacing: 0.52px;

    &-outline {
        background-color: transparent;
        box-shadow: 0 0 0 1px $Primary-Black;
        &:disabled {
            box-shadow: 0 0 0 1px rgba($Primary-Black, 0.2);
            &:hover,
            &:focus,
            &:active {
                background-color: transparent;
            }
        }
    }
    &-default {
        &:disabled {
            opacity: 0.2;
        }
    }
    &-primary {
        &:disabled {
            background-color: $Primary-LightRed;
            color: $white;
            opacity: 0.2;
            &:hover,
            &:focus,
            &:active {
                background-color: $Primary-LightRed;
            }
        }
    }
    &-secondary {
        &:disabled {
            opacity: 0.2;
        }
    }

    @media (max-width: $breakpoint-small-max) {
        box-sizing: border-box;
        width: 100%;
        max-width: 100%;

        &:not(.uk-button-outline) {
            border-radius: 0;
        }
    }

    @media (min-width: $breakpoint-medium) {
    }
}

//CONTAINER
.uk-container {
    width: 100%;
    box-sizing: border-box;

    &-small {
        margin-left: auto;
        margin-right: auto;
    }
    @media (min-width: $breakpoint-xxlarge) {
        &:not(&-small) {
            max-width: 1400px;
        }
        &-expand {
            max-width: none;
        }
    }
}
.uk-container-xxlarge {
    @media (min-width: $breakpoint-xxlarge) {
        width: 100%;
        box-sizing: border-box;
        margin-left: auto;
        margin-right: auto;
        max-width: 1920px;
    }
}

//FORM INPUT
.uk-input {
    border: 1px solid rgba($Primary-Black, 0.1);
    border-radius: 10px;
    font-weight: normal;
    line-height: 1.5;
}

.float-labels {
    position: relative;

    &.white {
        //input su bg color
        input::-ms-input-placeholder,
        input::placeholder {
            color: rgba($Primary-White, 0.4) !important;
        }
        input[type="date"]::-webkit-datetime-edit {
            color: $Primary-White;
        }
        input[type="date"]::-webkit-calendar-picker-indicator {
            filter: invert(1);
        }
        .uk-input {
            color: $Primary-White;
            border: 1px solid rgba($Primary-White, 0.1);

            + .floating-label {
                color: $Primary-White;
            }
            &:focus,
            &:not(:placeholder-shown) {
                & + .floating-label {
                    color: rgba($Primary-White, 0.4);
                }
            }
        }
    }

    .uk-input {
        &::placeholder {
            color: transparent;
        }

        & + .floating-label {
            padding: 0 7px;
            line-height: 50px;
            font-size: 14px;
            position: absolute;
            top: 0;
            left: 10px;
            user-select: none;
            transition: transform 0.25s;
            transform-origin: 0 0;
        }

        &:focus,
        &:not(:placeholder-shown) {
            & + .floating-label {
                transition: transform 0.25s;
                transform: translate(0px, -7px);
                background-color: transparent;
                -webkit-backdrop-filter: blur(1px);
                backdrop-filter: blur(1px);
                color: rgba($Primary-Black, 0.4);
                line-height: inherit;
                font-size: 10px;
                font-weight: normal;
                left: 2px;
                background: $creamer;
            }
        }
    }
}

//NAVBAR TITLE MOBILE
.navbar-title {
    margin-bottom: 18px;
    padding-top: 8px;

    .goback {
        width: 50px;
        height: 55px;
        display: flex;
        align-items: center;
        justify-content: center;

        i {
            font-size: 12px;
        }
    }

    span {
        font-size: 13px;
        font-weight: bold;
        line-height: 1.23;
        letter-spacing: 0.52px;
        text-align: center;
        text-transform: uppercase;
        width: calc(100% - 100px);
    }
}

//ACCORDION
.uk-accordion {
    > * {
        border: solid 1px rgba($Primary-Black, 0.2);
        border-radius: 5px;
        &.uk-open {
            .accordion-header {
                background-color: rgba($Second-LightGrey, 0.5);
                color: $Primary-Black;
            }
            .uk-form-icon {
                transform: rotate(90deg);
                transition: all 0.15s ease-in;
            }
        }
    }
    &-title {
        position: relative;
        padding: 11px 40px 11px 16px;
        .uk-form-icon {
            transition: all 0.15s ease-in;
        }
    }

    &-content {
        color: rgba($Primary-Black, 0.6);
        font-size: 13px;
        padding: 0 16px 20px;
    }
    @media (min-width: $breakpoint-medium) {
        &-content {
            font-size: 16px;
        }
    }
}

//SECTION
.section {
    &-rounded {
        border-radius: 10px 10px 0 0;
    }

    &-full-rounded {
        border-radius: 10px;
    }
}

//clean html dinamic from BO
.clean-html {
    p {
        margin-bottom: 0;
    }
}

//Scopri di più
.find-more {
    font-size: 15px;
    font-weight: normal;
    i {
        font-size: 30px;
    }
}

//MODAL OVERLAY
.Modal {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: $Primary-White;
    z-index: 1000;
    width: 100%;
    height: 100%;
    overflow-y: auto;
}
.Overlay {
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba($Primary-Black, 0.5);
    position: fixed;
    z-index: 1;
    overflow-y: initial;
}

//CLEAR HTML
.clear-html {
    p {
        margin: 0;
    }
}

.generic-go-back {
    .icon-container {
        margin-right: 10px;
        padding: 20px;
        i {
            font-size: 22px;
        }
    }
    a {
        display: flex;
        align-items: center;
        font-weight: bold;
    }
}
